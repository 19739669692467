import React, { useState, useEffect, useRef } from 'react';
import { Dialog as MuiDialog, DialogTitle, DialogContent, DialogActions, TextField, Button, IconButton, Snackbar, Slide, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet';
import './Dialog.css';
import { createLead } from '../../../Base/APICall';
import config from '../../../../config';

const SlideTransition = (props) => {
    return <Slide {...props} direction="right" />;
};

const Dialog = ({ open, onClose, mobileNumber, darkMode, showPartner }) => {
    const [mobile, setMobile] = useState('');
    const [name, setName] = useState('');
    const [partnerInfo, setPartnerInfo] = useState('');
    const [error, setError] = useState({ mobile: '', name: '' });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false);

    const nameInputRef = useRef(null);
    const mobileInputRef = useRef(null);

    useEffect(() => {
        if (open) {
            if (mobileNumber && /^\d{10}$/.test(mobileNumber)) {
                setMobile(mobileNumber);
                nameInputRef.current?.focus();
            } else {
                mobileInputRef.current?.focus();
            }
        }
    }, [open, mobileNumber]);

    const validateForm = () => {
        let isValid = true;
        let errors = { mobile: '', name: '' };

        if (!mobile || !/^\d{10}$/.test(mobile)) {
            errors.mobile = 'Please enter a valid 10-digit mobile number';
            isValid = false;
        }

        if (!name.trim()) {
            errors.name = 'Please enter your name';
            isValid = false;
        }

        setError(errors);
        return isValid;
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setLoading(true);
            const leadData = {
                name,
                phoneNumber: mobile,
                partnerInfo: showPartner ? partnerInfo : '',
                statusId: showPartner ? config.STATUS_DISTRIBUTOR : config.STATUS_NEW
            };

            try {
                const response = await createLead(leadData);

                if (response) {
                    setSnackbarMessage('Thank You for contacting us. Our team will contact you shortly!');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                    setName('');
                    setMobile('');
                    setPartnerInfo('');
                    onClose();
                } else {
                    throw new Error('Failed to create lead');
                }
            } catch (error) {
                console.error('API call failed:', error);
                setSnackbarMessage('Failed to create lead. Please try again.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
            }
        }
    };

    const closeHandler = () => {
        setName('');
        setMobile('');
        setPartnerInfo('');
        onClose();
    }

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content={`Request a demo or become a partner with Ezybill, India's leading GST accounting software hosted on Microsoft® Azure. Reach out to us to explore business opportunities and software features.`}
                />
                <meta
                    name="keywords"
                    content="Ezybill, GST Accounting Software, Request Demo, Become a Partner, Business Application, Microsoft Azure, Partner with Ezybill"
                />
                <meta
                    name="robots"
                    content="index, follow"
                />
            </Helmet>

            <MuiDialog
                open={open}
                className={`custom-dialog ${darkMode ? 'dark' : 'light'}`}
                maxWidth="xs"
                fullWidth
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        backdropFilter: 'blur(10px)',
                    },
                }}
            >
                <DialogTitle style={{ position: 'relative' }} className="dialog-title">
                    <span style={{ flexGrow: 1 }}>{showPartner ? "Become a Partner" : "Request Demo"}</span>
                    <IconButton
                        style={{
                            position: 'absolute',
                            top: '-18px',
                            right: '-14px',
                            color: 'red',
                        }}
                        className="dialog-close-button"
                        onClick={() => closeHandler()}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        inputRef={mobileInputRef}
                        id="outlined-basic"
                        variant="outlined"
                        className="dialog-input"
                        label="Enter Mobile Number"
                        value={mobile}
                        style={{ marginTop: '10px' }}
                        fullWidth
                        onChange={(e) => setMobile(e.target.value)}
                        error={!!error.mobile}
                        helperText={error.mobile}
                    />

                    &nbsp;
                    <TextField
                        inputRef={nameInputRef}
                        id="outlined-basic"
                        variant="outlined"
                        className="dialog-input"
                        label="Enter Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={!!error.name}
                        helperText={error.name}
                        fullWidth
                    />
                    &nbsp;

                    {showPartner && (
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            label="Enter your message"
                            className="dialog-input"
                            value={partnerInfo}
                            onChange={(e) => setPartnerInfo(e.target.value)}
                            multiline
                            rows={2}
                            fullWidth
                        />
                    )}

                    <p className="dialog-terms">
                        By proceeding you agree to our <a href='https://ezybill.in/terms.html' target='_blank' rel="noopener noreferrer" className="dialog-terms-link">T&C</a>
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        fullWidth
                        style={{ backgroundColor: '#0C4FE0' }}
                        onClick={handleSubmit}
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={24} style={{ color: '#fff' }} /> : null}
                    >
                        {loading ? null : showPartner ? 'SUBMIT REQUEST →' : 'REQUEST DEMO →'}
                    </Button>
                </DialogActions>
            </MuiDialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                TransitionComponent={SlideTransition}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={snackbarMessage}
                ContentProps={{
                    style: {
                        backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336',
                        color: '#fff',
                    },
                }}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </>
    );
};

export default Dialog;
