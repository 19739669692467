import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import './Pricing.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ButtonGroup from '../UI/Controls/Buttons/ButtonGroup';
import HeadingText from '../UI/Controls/HeadingText/HeadingText';
import PricingDailog from '../UI/Controls/Dialog/PricingDailog/PricingDailog';
import config from '../../config';
import { Helmet } from 'react-helmet';

const Packages = ({ darkMode }) => {
    const [showAllFeatures, setShowAllFeatures] = useState(false);
    const isMobileMode = useMediaQuery('(max-width: 768px)');
    const [openPricing, setOpenPrice] = useState(false);

    const silver = [
        {
            "id": 9,
            "name": "Silver",
            "description": "Silver Plan",
            "applicationId": 1,
            "amount": 6998.00,
            "currencyCode": "INR",
            "discountPercent": 50,
            "discountAmount": 0,
            "validityInDays": 365,
            "numberOfUsers": 1,
            "appModules": [
                {
                    "index": 1,
                    "active": true,
                    "description": "Unlimited Device Logins"
                },
                {
                    "index": 2,
                    "active": true,
                    "description": "Works on Laptop & Mobile (Anrdoid & iOS)"
                },
                {
                    "index": 3,
                    "active": true,
                    "description": "Send Invoice on WhatsApp/SMS/Email"
                },
                {
                    "index": 4,
                    "active": true,
                    "description": "Validate & Fetch Party details by GSTIN"
                },
                {
                    "index": 5,
                    "active": true,
                    "description": "E-Way Bill Generation (JSON Download)"
                },
                {
                    "index": 6,
                    "active": true,
                    "description": "GSTR-1 JSON Download, View GSTR2/3B"
                },
                {
                    "index": 7,
                    "active": true,
                    "description": "Track/View GST Returns Status"
                },
                {
                    "index": 8,
                    "active": true,
                    "description": "Sales Invoice"
                },
                {
                    "index": 9,
                    "active": true,
                    "description": "Delivery Challan"
                },
                {
                    "index": 10,
                    "active": true,
                    "description": "Quotation/Proforma"
                },
                {
                    "index": 11,
                    "active": true,
                    "description": "Sales Orders"
                },
                {
                    "index": 12,
                    "active": true,
                    "description": "Manage Incoming Payments/Receivables"
                },
                {
                    "index": 13,
                    "active": true,
                    "description": "Purchase Bills"
                },
                {
                    "index": 14,
                    "active": true,
                    "description": "Purchase Orders"
                },
                {
                    "index": 15,
                    "active": true,
                    "description": "Manage Outgoing Payments/Payables"
                },
                {
                    "index": 15,
                    "active": true,
                    "description": "Manage Returns Credit/Debit Notes"
                },
                {
                    "index": 15,
                    "active": true,
                    "description": "Manage Refunds"
                },
                {
                    "index": 16,
                    "active": true,
                    "description": "Maintain Perfect Stock/inventory"
                },
                {
                    "index": 17,
                    "active": true,
                    "description": "Stock Adjustments"
                },
                {
                    "index": 18,
                    "active": true,
                    "description": "Add Multiple Bank Accounts"
                },
                {
                    "index": 19,
                    "active": true,
                    "description": "Cheque Printing"
                },
                {
                    "index": 20,
                    "active": true,
                    "description": "Manage Expenses"
                },
                {
                    "index": 21,
                    "active": true,
                    "description": "25+ Advanced Reports"
                },
                {
                    "index": 22,
                    "active": true,
                    "description": "Dashboard"
                },
                {
                    "index": 23,
                    "active": true,
                    "description": "Free training & WhatsApp support."
                }
            ]
        },
    ];

    const gold = [
        {
            "id": 10,
            "name": "Gold",
            "description": "Gold Plan",
            "applicationId": 1,
            "amount": 8998.00,
            "currencyCode": "INR",
            "discountPercent": 50,
            "discountAmount": 0,
            "validityInDays": 365,
            "numberOfUsers": 3,
            "appModules": [
                {
                    "index": 1,
                    "active": true,
                    "description": "All features in Silver Plan +"
                },
                {
                    "index": 2,
                    "active": true,
                    "description": "Manage Multiple Users (Upto 3 Users)"
                },
                {
                    "index": 3,
                    "active": true,
                    "description": "Manage User Access Roles & Permissions"
                },
                {
                    "index": 4,
                    "active": true,
                    "description": "Automatic E-Way Bill Generation (500 per year)"
                },
                {
                    "index": 4,
                    "active": true,
                    "description": "Advanced Inventory Management - Batch/Serial # Tracking"
                },
                {
                    "index": 5,
                    "active": true,
                    "description": "Item Grouping - Create Items in Groups"
                },
                {
                    "index": 6,
                    "active": true,
                    "description": "Convert between Unit of Measurements"
                },
                {
                    "index": 7,
                    "active": true,
                    "description": "Sales Person Module"
                },
                {
                    "index": 8,
                    "active": true,
                    "description": "Generate Item Pick List"
                }
            ]
        },
    ];

    const Platinum = [
        {
            "id": 11,
            "name": "Platinum",
            "description": "Platinum Plan",
            "applicationId": 1,
            "amount": 0,
            "currencyCode": "INR",
            "discountPercent": 0,
            "discountAmount": 0,
            "validityInDays": 0,
            "numberOfUsers": 20,
            "appModules": [
                {
                    "index": 1,
                    "active": true,
                    "description": "All features in Gold Plan +"
                },
                {
                    "index": 2,
                    "active": true,
                    "description": "Automatic E-Invoice Generation (Unlimited^)"
                },
                {
                    "index": 3,
                    "active": true,
                    "description": "Create Composite Items (Bill of Materials)"
                },
                {
                    "index": 4,
                    "active": true,
                    "description": "Manage Multiple Users (Unlimited^)"
                },
                {
                    "index": 5,
                    "active": true,
                    "description": "Manage User Access Roles & Permissions"
                },
                {
                    "index": 6,
                    "active": true,
                    "description": "Automatic E-Way Bill Generation (Unlimited^)"
                },
                {
                    "index": 7,
                    "active": true,
                    "description": "Shopify Integration"
                },
                {
                    "index": 8,
                    "active": true,
                    "description": "Dedicated Support & Relationship Manager"
                }
            ]
        }
    ];
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const carouselParams = {
        additionalTransfrom: 0,
        arrows: false,
        autoPlaySpeed: 3000,
        centerMode: false,
        className: "",
        containerClass: "carousel-container",
        customButtonGroup: isMobileMode ? <ButtonGroup /> : null,
        dotListClass: "",
        draggable: isMobileMode ? true : false,
        focusOnSelect: false,
        infinite: true,
        itemClass: "",
        keyBoardControl: true,
        minimumTouchDrag: 80,
        renderButtonGroupOutside: true,
        renderDotsOutside: false,
        responsive: responsive,
        showDots: false,
        sliderClass: "",
        slidesToSlide: 1,
    }
    const openFeatureDialog = () => {
        setOpenPrice(true);
    }
    const closeFeatureDialog = () => {
        setOpenPrice(false);
    }
    const handleClick = () => {
        window.open(`https://www.gst.ezybill.in/upgradenow?cc=${config.COUPON_CODE}`, '_blank')
    }

    const getPlatinumPrice = () => {
        window.open('https://api.whatsapp.com/send/?phone=917760204876&text=Get Platinum Pricing&type=phone_number&app_absent=0', '_blank')
    }

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Explore value-for-money business plans tailored to suit small and medium-sized businesses in India. Choose from Silver, Gold, and Platinum Plans with exclusive features."
                />
                <meta
                    name="keywords"
                    content="ezybill plans, Silver plan for businesses, Gold plan pricing, Platinum plan, pricing plans for small businesses, business subscription plans, flexible business solutions, pricing, India business plans, small business subscription, medium business pricing, best business plans for startups, cost-effective business solutions, features of business plans"
                />
            </Helmet>


            <section id="pricing">
                <div className={`pricing-container ${darkMode ? 'dark' : 'light'}`}>
                    <Grid container spacing={2} style={{ marginTop: '-73px', }}>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={12} sm={6}>
                            <HeadingText mainHeading={"Value for Money Plans."} darkMode={darkMode} subHeading={"Affordable plans tailored to suit the needs of small and medium-sized businesses in India"} />

                        </Grid>
                        <Grid item xs={3}></Grid>
                    </Grid>
                    <Carousel
                        {...carouselParams}
                    >
                        <div className="table basic" style={{ margin: '10px', background: '#E2E7ED' }}>
                            <div className="head_tab"><h2 style={{ color: '#000', height: '30px' }}>Silver Plan <span style={{ color: 'black', fontSize: '34px' }}>👑</span></h2></div>
                            <div className="price-section" style={{ fontSize: '16px' }}>
                                <div className="inner-area">
                                    <span className="text" style={{ color: 'red', textDecoration: 'line-through' }}>₹</span>
                                    <span className="price" style={{ color: 'red', textDecoration: 'line-through' }}>6,998<span style={{ fontSize: "12px" }}></span></span>
                                </div>
                            </div>
                            <div className="price-section" style={{ fontSize: '24px', marginBottom: '10px' }}>
                                <div className="inner-area">
                                    <span className="text" style={{ color: 'green' }}>₹</span>
                                    <span className="price" style={{ color: 'green' }}>3,499.00<sup>*</sup> <span style={{ fontSize: "12px" }}></span></span>
                                </div>
                            </div>
                            <div className="package-name"></div>
                            <div className="btn"><button onClick={handleClick}>Buy Silver Plan</button></div>
                            <ul className="features" style={{ background: '#ffffff', borderRadius: '10px', paddingTop: '8px' }}>
                                {silver.map((data) => (
                                    <div key={data.id}>
                                        <ul style={{ marginLeft: '-64px' }}>
                                            {data.appModules.slice(0, showAllFeatures ? data.appModules.length : 5).map((item, index) => (
                                                <li key={item.index}>
                                                    <span className="list-name">{item.description}</span>
                                                    <span className="icon check">✅</span>
                                                </li>
                                            ))}
                                            {data.appModules.length > 5 && (
                                                <div style={{ color: 'blue', cursor: 'pointer' }} onClick={() => openFeatureDialog()}>
                                                    More features +
                                                </div>
                                            )}
                                        </ul>
                                    </div>
                                ))}
                            </ul>


                        </div>

                        <div className="table premium" style={{ margin: '10px', background: 'rgb(247 202 22 / 41%)' }}>
                            <div className="head_tab"><h2 style={{ color: '#000', height: '30px' }}>Gold Plan <span style={{ color: 'black', fontSize: '34px' }}>👑</span></h2></div>
                            <div className="ribbon"><span>Most Popular</span></div>
                            <div className="price-section" style={{ fontSize: '16px' }}>
                                <div className="inner-area">
                                    <span className="text" style={{ color: 'red', textDecoration: 'line-through' }}>₹</span>
                                    <span className="price" style={{ color: 'red', textDecoration: 'line-through' }}>8,998<span style={{ fontSize: "12px" }}></span></span>
                                </div>
                            </div>
                            <div className="price-section" style={{ fontSize: '24px', marginBottom: '10px' }}>
                                <div className="inner-area">
                                    <span className="text" style={{ color: 'green' }}>₹</span>
                                    <span className="price" style={{ color: 'green' }}>4,499.00<sup>*</sup> <span style={{ fontSize: "12px" }}></span></span>
                                </div>
                            </div>
                            <div className="package-name"></div>
                            <div className="btn"><button onClick={handleClick}>Buy Gold Plan</button></div>
                            <ul className="features" style={{ background: '#ffffff', borderRadius: '10px', paddingTop: '8px' }}>
                                {gold.map((data) => (
                                    <div key={data.id}>
                                        <ul style={{ marginLeft: '-64px' }}>
                                            {data.appModules.slice(0, showAllFeatures ? data.appModules.length : 5).map((item, index) => (
                                                <li key={item.index}>
                                                    <span className="list-name">{item.description}</span>
                                                    <span className="icon check">✅</span>
                                                </li>
                                            ))}

                                            {data.appModules.length > 5 && (
                                                <div style={{ color: 'blue', cursor: 'pointer' }} onClick={() => openFeatureDialog()}>
                                                    More features +
                                                </div>
                                            )}
                                        </ul>

                                    </div>
                                ))}

                            </ul>

                        </div>

                        <div className="table ultimate" style={{ margin: '10px', background: '#F0F4FD' }}>
                            <div className="head_tab"><h2 style={{ color: '#000', height: '30px' }}>Platinum Plan <span style={{ color: 'black', fontSize: '34px' }}>👑</span></h2></div>
                            <div className="price-section" style={{ fontSize: '16px' }}>
                                <div className="inner-area">
                                    <span className="text"></span>
                                    <span className="price" style={{ color: 'black' }}><span style={{ fontSize: "12px" }}>Priced according to your business requirements.</span></span>
                                </div>
                            </div>
                            <div className="price-section" style={{ fontSize: '24px', marginBottom: '10px' }}>
                                <div className="inner-area">
                                    <span className="text">&nbsp;</span>
                                    <span className="price">Flexi-Pricing<span style={{ fontSize: "12px" }}></span></span>
                                </div>
                            </div>

                            <div className="package-name"></div>
                            <div className="btn"><button style={{ background: '#01b2482a', color: 'darkgreen', margintop: '-63px', border: '1px solid darkgreen' }} onClick={getPlatinumPrice}>Get Platinum Price</button></div>
                            <ul className="features" style={{ background: '#ffffff', borderRadius: '10px', paddingTop: '8px' }}>
                                {Platinum.map((data) => (
                                    <div key={data.id}>
                                        <ul style={{ marginLeft: '-64px' }}>
                                            {data.appModules.slice(0, showAllFeatures ? data.appModules.length : 5).map((item, index) => (
                                                <li key={item.index}>
                                                    <span className="list-name">{item.description}</span>
                                                    <span className="icon check">✅</span>
                                                </li>
                                            ))}

                                            {data.appModules.length > 5 && (
                                                <div style={{ color: 'blue', cursor: 'pointer' }} onClick={() => openFeatureDialog()}>
                                                    More features +
                                                </div>
                                            )}
                                        </ul>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    </Carousel>
                </div >
                <PricingDailog open={openPricing} onClose={closeFeatureDialog} />
            </section >
        </>
    );
}

export default Packages;
