import React, { useState } from 'react';
import './Contact.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Snackbar, IconButton, Slide, Button, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createLead } from '../Base/APICall';
import config from '../../config';

const SlideTransition = (props) => {
    return <Slide {...props} direction="right" />;
};

const Contact = ({ darkMode }) => {
    const [formData, setFormData] = useState({ name: '', phone: '', message: '' });
    const [errors, setErrors] = useState({ phone: '' });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(false);

    const validateForm = () => {
        const { phone } = formData;
        let isValid = true;
        let formErrors = { phone: '' };

        if (!phone || !/^\d{10}$/.test(phone)) {
            formErrors.phone = 'Please enter a valid 10-digit phone number';
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);

            const { name, phone, message } = formData;

            try {
                const leadData = {
                    name,
                    phoneNumber: phone,
                    partnerInfo: message,
                    statusId: config.STATUS_NEW // STATUS ID
                };

                const response = await createLead(leadData); // Call your API
                if (response) {
                    setSnackbarMessage('Thank You for contacting us. Our team will contact you shortly!');
                    setSnackbarSeverity('success');
                    setFormData({ name: '', phone: '', message: '' }); // Reset form on success
                } else {
                    throw new Error('Failed to send message.');
                }
            } catch (error) {
                console.error('Failed to send message:', error);
                setSnackbarMessage('Failed to send message. Please try again.');
                setSnackbarSeverity('error');
            } finally {
                setSnackbarOpen(true);
                setLoading(false);
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <section id="contact">
            <div className={`contact-container ${darkMode ? 'dark' : 'light'}`}>
                <span className="big-circle"></span>
                <img src="img/shape.png" className="square" alt="" />
                <div className="form">
                    <div className="contact-info">
                        <h3 className="title">Let's get in touch</h3>

                        <div className="info">
                            <div className="information">
                                <i><MailIcon /></i>&nbsp;
                                <p>support@ezybill.in</p>
                            </div>
                            <div className="information">
                                <i><LocalPhoneIcon /></i>&nbsp;
                                <p>+91 94296 93550</p>
                            </div>
                        </div>

                        <div className="social-media">
                            <p>Connect with us:</p>
                            <div className="social-contact-icons">
                                <a href="https://www.facebook.com/groups/4564204143697979/?ref=share" target="_blank" rel="noopener noreferrer">
                                    <FacebookIcon />
                                </a>

                                <a href="https://www.instagram.com/ezybill.in/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noopener noreferrer">
                                    <InstagramIcon />
                                </a>

                                <a href="https://www.youtube.com/channel/UChxUStbZbK8zor-dgVXxd-g" target="_blank" rel="noopener noreferrer">
                                    <YouTubeIcon />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="contact-form">
                        <span className="circle one"></span>
                        <span className="circle two"></span>

                        <form className="contact-form-main" autoComplete="off" onSubmit={handleSubmit}>
                            <h3 className="title">Contact & Support</h3>

                            <div className="input-container">
                                <p style={{ color: '#fff', height: '11px' }}>Name</p>
                                <input
                                    type="text"
                                    name="name"
                                    className="input"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="input-container">
                                <p style={{ color: '#fff', height: '11px' }}>Phone</p>
                                <input
                                    type="tel"
                                    name="phone"
                                    className="input"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.phone && <p className="error">{errors.phone}</p>}
                            </div>

                            <div className="input-container textarea">
                                <p style={{ color: '#fff', height: '11px' }}>Message</p>
                                <textarea
                                    name="message"
                                    className="input"
                                    value={formData.message}
                                    onChange={handleChange}
                                ></textarea>
                            </div>

                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                className="contact-btn"
                                disabled={loading}
                                onClick={handleSubmit}
                                startIcon={loading ? <CircularProgress size={18} style={{ color: '#000' }} /> : null}
                                style={{
                                    backgroundColor: '#fff',
                                    color: '#000',
                                    padding: '8px 0',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    boxShadow: 'none',
                                    borderRadius: '4px',
                                }}
                            >
                                {loading ? '' : 'Submit'}
                            </Button>
                        </form>
                    </div>
                </div>

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    message={snackbarMessage}
                    ContentProps={{
                        style: {
                            backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336',
                            color: '#fff',
                        },
                    }}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </div>
        </section>
    );
};

export default Contact;
