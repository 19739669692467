import React, { useEffect, useState } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazyload';
import PrimaryButton from '../UI/Controls/Buttons/PrimaryButton';
import Landing from '../../assets/images/ezybill_banner.png';
import FlashSale from '../../assets/images/landing.JPG';
import './LandingPage.css';

const LandingPage = ({ darkMode, setOpenDialog, setMobileNumber }) => {
    const isMobile = useMediaQuery('(max-width: 768px)');
    const [showFirecrackers, setShowFirecrackers] = useState(true);
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowBanner(true);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => setShowFirecrackers(false), 5000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <section id="home">
            <Helmet>
                <meta
                    name="description"
                    content="Discover the best billing software for your business. Ezybill offers GST invoicing, supermarket, and medical billing solutions."
                />
                <meta
                    name="keywords"
                    content="Top 10 Easy To Use Billing and Invoicing Software in India, Free Invoicing Software for Small Businesses, GST Billing Software Free Download, Best Billing Software for Retail, Supermarket Billing Software, Free Invoice Software, Medical Billing Software, Grocery Store Billing Software"
                />
                <meta property="og:title" content="India's Best GST Accounting Software - Ezybill" />
                <meta
                    property="og:description"
                    content="Ezybill is India's most loved GST Accounting Software hosted on Microsoft® Azure, offering a secure and cost-effective enterprise-grade solution."
                />
                <meta property="og:image" content={Landing} />
                <meta property="og:url" content="https://www.ezybill.in" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        '@context': 'http://schema.org',
                        '@type': 'SoftwareApplication',
                        name: 'Ezybill',
                        description:
                            "India's most loved GST Accounting Software hosted on Microsoft® Azure.",
                        applicationCategory: 'BusinessApplication',
                        operatingSystem: 'All',
                        offers: {
                            '@type': 'Offer',
                            price: '0.00',
                            priceCurrency: 'INR',
                            availability: 'http://schema.org/InStock',
                        },
                        url: 'https://www.ezybill.in',
                        image: Landing,
                    })}
                </script>
            </Helmet>


            <main className={`landing-page ${darkMode ? 'dark' : 'light'}`}>
                <Grid
                    container
                    style={{ marginTop: isMobile ? '70px' : '90px' }}
                    spacing={2}
                    alignItems="center"
                >
                    <Grid item xs={12} sm={12} md={7} lg={6} className="text-section">
                        <header>
                            <Typography variant="h4" className="main-title">
                                <span style={{ fontWeight: 'bold' }}>The Easiest GST Billing & Inventory Software — Just ₹10/Day!</span>

                            </Typography>
                        </header>
                        {isMobile && (
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <LazyLoad height={200} offset={100}>
                                    <img
                                        // className="animated-image"
                                        src={FlashSale}
                                        alt="ezybill GST Billing & Inventory Software"
                                        style={{
                                            width: '90%',
                                            height: 'auto',
                                            // border: '2px solid #E4ECFA',
                                            // borderRadius: '10px',
                                            padding: '8px',
                                            margin: '8px',
                                            borderRadius: '15px'
                                        }}
                                        // onClick={() => { window.open("https://www.gst.ezybill.in/upgradenow?cc=LASTCALL", "_blank"); }}
                                    />
                                </LazyLoad>
                            </Grid>
                        )}
                        <article>
                            <p className="hosted-text">
                                Generate invoices, file GST returns & manage inventory—secure, fast & hassle-free. Trusted by growing businesses across India!
                            </p>
                        </article>
                        <Grid
                            container
                            spacing={2}
                            className="button-group"
                            style={{ textAlign: 'center' }}
                        >
                            <Grid item>
                                <PrimaryButton darkMode={darkMode} external={true} color='secondary'>
                                    Start 15-Day Free Trial
                                </PrimaryButton>
                            </Grid>
                            <Grid item>
                                <PrimaryButton darkMode={darkMode} external={false} handleClick={() => { setOpenDialog(true) }}>
                                    Request Demo
                                </PrimaryButton>
                            </Grid>

                        </Grid>
                    </Grid>

                    {!isMobile && (
                        <Grid
                            item
                            xs={12}
                            sm={5}
                            md={5}
                            lg={6}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <LazyLoad height={200} offset={100}>
                                <img
                                    // className="animated-image"
                                    src={FlashSale}
                                    alt="ezybill GST Billing & Inventory Software"
                                    style={{
                                        width: '81%',
                                        height: 'auto',
                                        padding: '8px',
                                        margin: '8px',
                                        cursor: 'pointer',
                                        borderRadius: '15px'
                                    }}
                                    // onClick={() => { window.open("https://www.gst.ezybill.in/upgradenow?cc=LASTCALL", "_blank"); }}

                                />
                            </LazyLoad>
                        </Grid>
                    )}
                </Grid>
            </main>
        </section>
    );
};

export default LandingPage;
