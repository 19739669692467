import React, { useState, useEffect } from 'react';
import { Dialog as MuiDialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './MobileOffer.css';
import PrimaryButton from './../UI/Controls/Buttons/PrimaryButton';
import MobileOfferBanner from '../../assets/images/mobileoffer.png';
import config from './../../config'; // Import the config

const MobileOffer = ({ open, onClose }) => {
    const [timer, setTimer] = useState(0);
    useEffect(() => {
        const startDate = new Date(config.COUPON_START_DATE);
        const endDate = new Date(config.COUPON_END_DATE);
        const currentTime = Date.now();

        if (currentTime < startDate) {
            setTimer(0);
        } else if (currentTime >= startDate && currentTime <= endDate) {
            const differenceInMilliseconds = endDate.getTime() - currentTime;
            setTimer(differenceInMilliseconds);
        } else {
            setTimer(0);
        }
    }, []);


    useEffect(() => {
        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1000);
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [timer]);

    const formatTimer = () => {
        const totalSeconds = Math.floor(timer / 1000);
        const days = Math.floor(totalSeconds / 86400);
        const hours = Math.floor((totalSeconds % 86400) / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return days > 0
            ? `${days} Day${days > 1 ? 's' : ''} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
            : `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handleClick = () => {
        window.open(`https://www.gst.ezybill.in/upgradenow?cc=${config.COUPON_CODE}`, '_blank');
    };

    return (
        <MuiDialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            BackdropProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    backdropFilter: 'blur(10px)',
                },
            }}
            PaperProps={{
                style: {
                    borderRadius: '20px',
                    padding: '10px',
                    backgroundColor: '#f9f9f9',
                    textAlign: 'center',
                    maxWidth: '350px',
                },
            }}
        >
            <DialogTitle style={{ position: 'relative' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold', color: 'navy' }}>
                    Exclusive Offer Just for You!
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{ position: 'absolute', right: '-9px', top: '-12px', color: 'red' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {/* <Typography variant="body1" style={{ marginTop: '10px', fontWeight: 'bold', color: 'red' }}>
                    Offer expires in:
                </Typography> */}
                <div className="offer-popup-image-container">
                    <div className="offer-popup-image-overlay">
                        <img
                            src={MobileOfferBanner}
                            alt="Offer"
                            style={{ width: '100%' }}
                            className="offer-popup-image"
                        />                        
                        <Typography variant="h6" className="overlay-text">
                            FLAT<br/>
                            {config.OFFER_DESCRIPTION}
                        </Typography>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <PrimaryButton external={false} handleClick={handleClick}>Claim Now</PrimaryButton>
            </DialogActions>
        </MuiDialog>
    );
};

export default MobileOffer;
